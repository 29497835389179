import { Box, Button } from "@mui/material";
import {
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from "@mui/x-data-grid";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  termLabel,
  definitionsLabel,
  doDelete,
  doEdit,
  add,
  failedToGetTerms,
  termDeleted,
  termAdded,
  termEdited,
  doEditQuestions,
  numberOfQuestionsLabel,
} from "../../../services/Messages";
import { Constants } from "../../../Constants";
import ApiService from "../../../services/ApiService";
import { Term } from "../../../types/terms/Term";
import { Definition } from "../../../types/terms/Definition";
import { AddOrEditTermModal } from "./AddOrEditTermModal";
import { DeleteTermModal } from "./DeleteTermModal";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { DataTable } from "../../../components/DataTable";
import { ManageTermQuestionsModal } from "./ManageTermQuestionsModal";

interface TermsTableProps {
  topicId: string;
  user: User;
}

export const TermsTable = ({ topicId, user }: TermsTableProps) => {
  const recommendedTermsCount: number = 4;

  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: Constants.ITEMSPERPAGE,
    page: 0,
  });
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [terms, setTerms] = useState<Array<Term>>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [term, setTerm] = useState<Term | null>(null);
  const [questionsTerm, setQuestionsTerm] = useState<Term | null>(null);
  const [totalTerms, setTotalTerms] = useState<number>(0);
  const [showAmendTerm, setShowAmendTerm] = useState<boolean>(false);
  const [showManageTermQuestions, setShowManageTermQuestions] =
    useState<boolean>(false);
  const [showDeleteTerm, setShowDeleteTerm] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: "text",
      headerName: termLabel,
      width: 250,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "definitions",
      headerName: definitionsLabel,
      width: 350,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const df: Definition[] = params.value as Definition[];
        let val: string = "";
        df.map((d, index) => {
          val += d.text;
          if (index !== df.length - 1) {
            val += ", ";
          }
          return val;
        });
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {val}
          </span>
        );
      },
    },
    {
      field: "numQuestions",
      headerName: numberOfQuestionsLabel,
      width: 100,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "resources",
      headerName: "Resources",
      width: 200,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "edit",
      headerName: "",
      width: 100,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const t: Term = params.row as Term;
        return (
          <Button
            variant="contained"
            onClick={(event) => {
              onEditTerm(t);
            }}
          >
            <EditIcon />
            &nbsp;{doEdit}
          </Button>
        );
      },
    },
    {
      field: "questions",
      headerName: "",
      width: 150,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const t: Term = params.row as Term;
        return (
          <Button
            variant="contained"
            onClick={(event) => {
              onManageTermQuestions(t);
            }}
          >
            <EditIcon />
            &nbsp;{doEditQuestions}
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const t: Term = params.row as Term;
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              onDeleteTerm(t);
            }}
          >
            <DeleteIcon />
            &nbsp;{doDelete}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchTerms = async (pageIndex: number) => {
      const token = await user.getIdToken();
      const terms = await ApiService.getTerms(topicId, token, pageIndex);
      setTerms(terms.terms);
      setTotalTerms(terms.total);
      setShowAlert(terms.total < recommendedTermsCount);
    };
    fetchTerms(paginationModel.page)
      .then(() => {
        setMessage({ message: "", variant: "info" });
        setDataLoading(false);
      })
      .catch((e) => {
        setMessage({ message: failedToGetTerms, variant: "error" });
        setDataLoading(false);
      });
  }, [topicId, paginationModel.page]);

  const onEditTerm = (term: Term) => {
    setTerm(term);
    setShowAmendTerm(true);
  };

  const onManageTermQuestions = (term: Term) => {
    setTerm(null);
    setQuestionsTerm(term);
    setShowManageTermQuestions(true);
  };

  const onDeleteTerm = (term: Term) => {
    setMessage({ message: "", variant: "info" });
    setTerm(term);
    setShowDeleteTerm(true);
  };

  const onCancelDeleteTerm = () => {
    setTerm(null);
    setShowDeleteTerm(false);
  };

  const onTermDeleted = (term: Term) => {
    let t = terms.filter((q) => q.id !== term.id);
    setTerms(t);
    setTerm(null);
    setShowDeleteTerm(false);
    setMessage({ message: termDeleted, variant: "success" });
  };

  const onAddTerm = () => {
    setTerm(null);
    setShowAmendTerm(true);
  };

  const onCancelEditTerm = () => {
    setTerm(null);
    setShowAmendTerm(false);
  };

  const onClosedManageTermQuestions = (numQuestions: number) => {
    const termIndex = terms.findIndex((t) => t.id === questionsTerm?.id);
    terms[termIndex].numQuestions = numQuestions;
    setTerms(terms);
    setQuestionsTerm(null);
    setShowManageTermQuestions(false);
  };

  const onTermAmended = (term: Term, wasAdded: boolean) => {
    let resetTerms = terms.map((t) => ({ ...t, newOrModified: false }));
    if (wasAdded) {
      setTerms([term, ...resetTerms]);
      setShowAmendTerm(false);
      setTerm(null);
      setMessage({ message: termAdded, variant: "success" });
    } else {
      const termIndex = resetTerms.findIndex((t) => t.id === term.id);
      resetTerms[termIndex] = term;
      setTerms(resetTerms);

      setTerm(null);
      setShowAmendTerm(false);
      setMessage({ message: termEdited, variant: "success" });
    }
  };

  const showNewTerm = (params: GridRowParams) => {
    return params.row.newOrModified != null && params.row.newOrModified
      ? "highlight"
      : "";
  };

  return (
    <>
      <AddOrEditTermModal
        show={showAmendTerm}
        topicId={topicId}
        term={term}
        user={user}
        onSubmitCallback={onTermAmended}
        onCancelCallback={onCancelEditTerm}
      />

      <DeleteTermModal
        show={showDeleteTerm}
        topicId={topicId}
        term={term}
        user={user}
        onSubmitCallback={onTermDeleted}
        onCancelCallback={onCancelDeleteTerm}
      />

      <ManageTermQuestionsModal
        show={showManageTermQuestions}
        topicId={topicId}
        term={questionsTerm}
        user={user}
        onClosedCallback={onClosedManageTermQuestions}
      />

      {message.message.length > 0 && (
        <MessageComponent message={message.message} variant={message.variant} />
      )}

      <DataTable
        loading={dataLoading}
        columns={columns}
        rows={terms}
        totalRows={totalTerms}
        getRowClassNameFunc={showNewTerm}
        paginationModel={paginationModel}
        paginationModelChanged={setPaginationModel}
      />

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" onClick={onAddTerm}>
          <AddIcon />
          &nbsp;{add}
        </Button>
      </Box>
    </>
  );
};
